import SEO from "../components/seo"
import React, { useEffect, useState } from "react"
import logoBrahma from "../images/promos/brahma-logo.svg"
import logoQuilmes from "../images/logos/newLogoMenu.svg"
import promosBannerDesktop from "../images/banners/promos-desktop.jpeg"
import promosBannerMobile from "../images/banners/promos-mobile.jpeg"
import { IsMobileDevice } from "../../utils/ismobile"
import { Checkbox, Input, message, Select, Spin } from "antd"
import { Button } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import provincesAndCities from "../../utils/provincesAndCities"
import pdvCodes from "../../utils/pdv-codes"

const PromosPage = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [beesCode, setBeesCode] = useState(null)
  const [address, setAddress] = useState("")
  const [brahmaChecked, setBrahmaChecked] = useState(false)
  const [quilmesChecked, setQuilmesChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [province, setProvince] = useState(null)
  const [city, setCity] = useState(null)
  const provinces = Object.keys(provincesAndCities)
  const [step, setStep] = useState(0)
  const validCodes = pdvCodes.map(pdv => String(pdv.code))

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {
    const steps = [
      "Código Bees",
      "Domicilio",
      "Provincia",
      "Localidad",
      "Adhesión Brahma",
      "Adhesión Quilmes",
    ]

    if (step > 0) {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso ${step}`,
        event_label: steps[step - 1],
        interaction: "True",
        component_name: "boton_adherite",
      })
    }
  }, [step])

  const provincesOptions = provinces.map(province => ({
    value: province,
    label: province,
  }))

  const getCitiesOptions = () =>
    provincesAndCities[province]?.map(city => ({
      value: city,
      label: city,
    }))

  const showSuccess = (message = "Te registraste correctamente en la promo!") =>
    toast.success(message, {
      position: "top-center",
    })
  const showError = error =>
    toast.error(error, {
      position: "top-center",
    })

  const submit = async () => {
    if (loading) return
    let error

    // if (!address) {
    //   error = "Por favor ingrese un domicilio"
    // }
    //
    // if (!province) {
    //   error = "Por favor seleccione una provincia"
    // }
    //
    // if (!city) {
    //   error = "Por favor seleccione una localidad"
    // }
    if(hideBrahma){
      if(!quilmesChecked){
        error = "Debe aceptar la promo Quilmes"
      }
    }else{
      if (!brahmaChecked) {
        error = "Debe aceptar al menos una promo"
      }
    }
    if (error) {
      showError(error)
      return
    }
    // if(beesCode && !validCodes.includes(beesCode)){
    //   error = "Código bees no se encuentra adherido"
    // }
    const apiUrlCheck = 
                // "http://localhost:8080/api/bees-codes/exists"
                "https://wacopaamericamotmback-prd.azurewebsites.net/api/bees-codes/exists"
    try{
      const responseCheck = await fetch(apiUrlCheck, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: beesCode,
        }),
      })
  
      const dataCheck = await responseCheck.json()
      if (!dataCheck.exists) {
        error = "Código bees no se encuentra adherido"
      }
  
      if (!beesCode) {
        error = "Por favor ingrese un código Bees"
      }
  
      if (error) {
        showError(error)
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Form",
          event_action: "Error",
          event_label: error,
          interaction: "True",
          component_name: "boton_enviar",
        })
        return
      }
  
      setLoading(true)
  
      const apiUrl =
        // "http://localhost:8080/api/promos"
        "https://wacopaamericamotmback-prd.azurewebsites.net/api/promos"
  
      try{
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            bees_code: beesCode,
            address: "No completado",
            brahma_checked: brahmaChecked,
            quilmes_checked: quilmesChecked,
            province: "No completado",
            city: "No completado",
          }),
        })
    
        const data = await response.json()
    
        if (data.success) {
          setAddress("")
          setBeesCode("")
          setBrahmaChecked(false)
          setQuilmesChecked(false)
          setProvince(null)
          setCity(null)
          showSuccess("Te registraste correctamente en la promo!")
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Form",
            event_action: "Success",
            event_label: "",
            interaction: "True",
            component_name: "boton_enviar",
            userUid: data.result.insertId,
          })
        } else {
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Form",
            event_action: "Error",
            event_label: data.message,
            interaction: "True",
            component_name: "boton_enviar",
          })
          showError(data.message)
        }
    
        setLoading(false)
  
      }catch(e){
        console.log(e)
        showError("Error de conexión")
        setLoading(false)
      }
    }catch(e){
      console.log(e)
      showError("Error de conexión")
      setLoading(false)
    }
  }

  const cancelSubcription = async () => {
    if (loading) return
    let error
    // if (!brahmaChecked && !quilmesChecked) {
    //   error = "Debe aceptar al menos una promo"
    // }
    const apiUrlCheck = 
                // `http://localhost:8080/api/promos/bees_code?code=${beesCode}`
                `https://wacopaamericamotmback-prd.azurewebsites.net/api/promos/bees-code/${beesCode}`
    try{
      const responseCheck = await fetch(apiUrlCheck, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
  
      const res = await responseCheck.json()
      const dataCheck = res[0]
      if (!dataCheck.id) {
        error = "Código bees no se encuentra adherido"
      }
  
      if (!beesCode) {
        error = "Por favor ingrese un código Bees"
      }
  
      if (error) {
        showError(error)
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Form",
          event_action: "Error",
          event_label: error,
          interaction: "True",
          component_name: "boton_enviar",
        })
        return
      }
  
      setLoading(true)
  
      const apiUrl =
        // "http://localhost:8080/api/promos"
        "https://wacopaamericamotmback-prd.azurewebsites.net/api/promos"
  
      try{
        const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: dataCheck.id,
            bees_code: beesCode,
            canceled: 1,
          }),
        })
    
        const data = await response.json()
    
        if (data.success) {
          setAddress("")
          setBeesCode("")
          setBrahmaChecked(false)
          setQuilmesChecked(false)
          setProvince(null)
          setCity(null)
          showSuccess("Código Bees eliminado de promo adhesión")
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Form",
            event_action: "Success",
            event_label: "",
            interaction: "True",
            component_name: "boton_cancelar",
            userUid: data.result.insertId,
          })
        } else {
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Form",
            event_action: "Error",
            event_label: data.message,
            interaction: "True",
            component_name: "boton_cancelar",
          })
          showError(data.message)
        }
    
        setLoading(false)
  
      }catch(e){
        console.log(e)
        showError("Error de conexión")
        setLoading(false)
      }
    }catch(e){
      console.log(e)
      showError("Error de conexión")
      setLoading(false)
    }
    
  }

  const dueDate = new Date("2024-07-01")
  const currentDate = new Date()
  const hideBrahma = currentDate > dueDate

  return (
    <>
      <SEO title={"Promos"} description={""} />
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Cambiar a columna para que todo ocupe el 100% del ancho
    width: "100%", // Asegurar que el contenedor ocupe el 100% del ancho
        }}
      >
        <section
          style={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            minHeight: "100vh",
            width: "100%",
            flexDirection: "column",
            fontFamily: "Tungsten",
            background: "#031532",
            justifyContent: isMobile ? "center" : "space-evenly",
            gap: "2vh",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "800px",
              justifyContent: "center",
              gap: "20%",
            }}
          >
            <div
              style={{
                width: isMobile ? "40%" : "30%",
                aspectRatio: "1/1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <a
                target="_blank"
                href={"https://www.quilmes.com.ar"}
                style={{
                  width: "100%",
                  minHeight: "90px",
                  height: "100% !important",
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <img
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    height: "100%",
                    minHeight: isMobile ? "200px" : "auto",
                    objectFit: "contain",
                    marginBottom: isMobile ? "15%" : 0,
                    marginTop: 0,
                  }}
                  src={logoQuilmes}
                  alt={"quilmes"}
                />
              </a>
              <a
                target="_blank"
                href={"/Quilmes_Rock.webp"}
                style={{
                  textDecoration: "none",
                  // marginTop: isMobile ? "-15%" : 0,
                  textAlign: "center",
                  color: "white",
                  fontFamily: "Tungsten-Medium",
                  fontSize: isMobile ? "18px" : "1.7rem",
                }}
              >
                {/*<Button
                  style={{
                    fontSize: isMobile ? "18px" : "1.7rem",
                    width: isMobile ? "auto" : "200px",
                    marginTop: isMobile ? "5vh" : 0,
                    backgroundColor: "#092f56",
                    borderColor: "#092f56",
                  }}
                >
                  Conocé Promo Rock
                </Button>*/}
              </a>
            </div>
            {!hideBrahma && (
              <div
                style={{
                  width: isMobile ? "40%" : "30%",
                  aspectRatio: "1/1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <a
                  target="_blank"
                  href={"/Flyer-Promo-Brahma.jpg"}
                  style={{
                    width: "100%",
                    height: "100% !important",
                    minHeight: "90px",
                    display: "flex",
                    flexGrow: 1,
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      height: "100%",
                      minHeight: isMobile ? "100px" : "auto",
                      objectFit: "contain",
                      marginBottom: isMobile ? "15%" : 0,
                      marginTop: 0,
                    }}
                    src={logoBrahma}
                    alt={"brahma"}
                  />
                </a>
                <a
                  target="_blank"
                  href={"/Flyer-Promo-Brahma.jpg"}
                  style={{
                    textDecoration: "none",
                    // marginTop: isMobile ? "-15%" : 0,
                    textAlign: "center",
                    color: "white",
                    fontFamily: "Tungsten-Medium",
                    fontSize: isMobile ? "18px" : "1.7rem",
                  }}
                >
                  <Button
                    style={{
                      width: isMobile ? "auto" : "200px",
                      fontSize: isMobile ? "18px" : "1.7rem",
                    }}
                  >
                    Conocé promo chapitas
                  </Button>
                </a>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: isMobile ? "5px" : "2vh",
              marginTop: isMobile ? "5vh" : "0",
            }}
          >
            <h2
              style={{
                color: "white",
                fontSize: isMobile ? "40px" : "3.5rem",
                margin: 0,
                marginTop: isMobile ? "50px" : "0",
              }}
            >
             {/*< {hideBrahma ? "Promo Quilmes" : "Promos Quilmes y Brahma"}*/}
            </h2>
            {/*<ArrowDownOutlined*/}
            {/*  style={{*/}
            {/*    margin: 0,*/}
            {/*    fontSize: isMobile ? "20px" : "1.5rem",*/}
            {/*    cursor: "pointer",*/}
            {/*    animation: "updown 2s infinite",*/}
            {/*  }}*/}
            {/*  onClick={() => {*/}
            {/*    document*/}
            {/*      .getElementById("banner")*/}
            {/*      .scrollIntoView({ behavior: "smooth" })*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
          {/*{isMobile && (
            <img
              id={"banner"}
              src={promosBannerMobile}
              alt={"banner"}
              style={{
                width: "100%",
                objectFit: "contain",
              }}
            />
          )}*/}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: isMobile ? "80%" : "450px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/*<Input
                placeholder={"Código Bees"}
                style={{
                  height: "44px",
                  width: "100%",
                  fontSize: "1.7rem",
                  fontFamily: "Tungsten-Medium",
                  background: "white",
                }}
                // type={"numeric"}
                onChange={e => {
                  const lastChar = e.target.value.slice(-1)
                  // if (e.target.value === "" || !isNaN(parseInt(lastChar))) {
                    setBeesCode(
                      e.target.value.length > 14
                        ? e.target.value.slice(0, 18)
                        : e.target.value
                    )
                  // }
                }}
                // pattern="[0-9]*"
                value={beesCode}
                onBlur={() => {
                  setStep(1)
                }}
              />*/}
              <p
                style={{
                  color: "white",
                  fontFamily: "Tungsten-Medium",
                  fontSize: isMobile ? "3.1rem" : "3.35rem",
                  textAlign: "center",
                  paddingBottom:  isMobile ? "17rem" : "12.35rem",
                  
                }}
              >
                LA ADHESIÓN A LA PROMO YA NO ESTÁ DISPONIBLE
              </p>
            </div>
            {/*<div*/}
            {/*  style={{*/}
            {/*    width: isMobile ? "80%" : "450px",*/}
            {/*    display: "flex",*/}
            {/*    flexDirection: "column",*/}
            {/*    marginBottom: "20px",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Input*/}
            {/*    placeholder={"Domicilio"}*/}
            {/*    style={{*/}
            {/*      height: "44px",*/}
            {/*      width: "100%",*/}
            {/*      fontSize: "1.5rem",*/}
            {/*      fontFamily: "Tungsten-Medium",*/}
            {/*      background: "white",*/}
            {/*    }}*/}
            {/*    onChange={e => setAddress(e.target.value)}*/}
            {/*    value={address}*/}
            {/*    onBlur={() => {*/}
            {/*      setStep(2)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  style={{*/}
            {/*    width: isMobile ? "80%" : "450px",*/}
            {/*    display: "flex",*/}
            {/*    flexDirection: "column",*/}
            {/*    marginBottom: "20px",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Select*/}
            {/*    placeholder={"Provincia"}*/}
            {/*    style={{*/}
            {/*      height: "44px",*/}
            {/*      width: "100%",*/}
            {/*      fontSize: isMobile ? "18px" : "1.5rem",*/}
            {/*      fontFamily: "Tungsten-Medium",*/}
            {/*      background: "white",*/}
            {/*      display: "flex",*/}
            {/*      alignItems: "center",*/}
            {/*      justifyContent: "center",*/}
            {/*    }}*/}
            {/*    onBlur={() => {*/}
            {/*      setStep(3)*/}
            {/*    }}*/}
            {/*    bordered={false}*/}
            {/*    options={provincesOptions}*/}
            {/*    value={province}*/}
            {/*    showSearch={true}*/}
            {/*    onChange={value => {*/}
            {/*      setProvince(value)*/}
            {/*      setCity(null)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  style={{*/}
            {/*    background: "green",*/}
            {/*    width: isMobile ? "80%" : "450px",*/}
            {/*    display: "flex",*/}
            {/*    flexDirection: "column",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Select*/}
            {/*    placeholder={"Localidad"}*/}
            {/*    style={{*/}
            {/*      height: "44px",*/}
            {/*      width: "100%",*/}
            {/*      fontSize: isMobile ? "18px" : "1.5rem",*/}
            {/*      fontFamily: "Tungsten-Medium",*/}
            {/*      background: "white",*/}
            {/*      display: "flex",*/}
            {/*      alignItems: "center",*/}
            {/*      justifyContent: "center",*/}
            {/*    }}*/}
            {/*    onBlur={() => {*/}
            {/*      setStep(4)*/}
            {/*    }}*/}
            {/*    onChange={value => setCity(value)}*/}
            {/*    value={city}*/}
            {/*    bordered={false}*/}
            {/*    showSearch={true}*/}
            {/*    disabled={!province}*/}
            {/*    options={province ? getCitiesOptions() : []}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
          <div>
            {!hideBrahma &&
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/*<Checkbox
                  checked={brahmaChecked}
                  onChange={e => setBrahmaChecked(e.target.checked)}
                  onClick={() => {
                    setStep(5)
                  }}
                />*/}
                <p
                  style={{
                    color: "white",
                    fontFamily: "Tungsten-Medium",
                    fontSize: isMobile ? "16px" : "1.65rem",
                    margin: 0,
                  }}
                >
                  Aceptación de adhesión a promo Brahma +{" "}
                  <a
                    href={
                      "/Términos y Condiciones Promo Brahma 2024 para PDV v9.pdf"
                    }
                    target={"_blank"}
                  >
                    Términos y Condiciones.
                  </a>
                </p>
              </div>
            }
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
             {/* <Checkbox
                checked={quilmesChecked}
                onChange={e => setQuilmesChecked(e.target.checked)}
                onClick={() => {
                  setStep(6)
                }}
              />*/}
              {/*<p
                style={{
                  color: "white",
                  fontFamily: "Tungsten-Medium",
                  fontSize: isMobile ? "16px" : "1.65rem",
                  margin: 0,
                }}
              >
                Aceptación de adhesión a promo Quilmes +{" "}
                <a
                  href={
                    "/Enero-ByC-PromoBajo-Tapa-PDV.pdf"
                  }
                  target={"_blank"}
                >
                  Términos y Condiciones.
                </a>
              </p>*/}
            </div>
          </div>
          {/*<Button
            style={{
              width: "200px",
              fontSize: "2rem",
              marginBottom: "0vh",
            }}
            onClick={submit}
          >
            {loading ? <Spin /> : "¡Adherite!"}
          </Button>*/}
          {/*<Button
            style={{
              fontFamily: "Tungsten-Book",
              fontSize: "1.3rem",
              margin: 0,
              padding: '5px 10px'
            }}
            variant="danger"
            onClick={cancelSubcription}
          >
            {loading ? <Spin /> : "Cancelar adhesión"}
          </Button>*/}
        </section>
        {!isMobile && (
          <section
            id={"banner"}
            //src={promosBannerDesktop}
            alt={"banner"}
            style={{
              width: "60vw",
              objectFit: "contain",
              background: "#031532",
            }}
          />
        )}
      </div>
    </>
  )
}

export default PromosPage
